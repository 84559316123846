import React, { useState, useRef } from "react"
import { Field } from "formik"
import axios from "axios"
import Axios from "../../config/config"
import path from "../../config/path"
import Loadable from "react-loadable"

// Components
import {
  UploadPhoto,
  UploadVideo,
  CloseBtn,
  DropdownIcon,
  CalenderIcon,
} from "../icons"

// Images
import loader from "../../images/loading.svg"

// Stylesheet
import styles from "./index.module.scss"

// Loadable Editor component
const LoadableEditor = Loadable({
  loader: () => import("./Editor.jsx"),
  loading: () => <div>Loading...</div>,
})

export const Input = ({
  label,
  required,
  type,
  placeholder,
  classes,
  error = false,
  ...props
}) => {
  return (
    <div className={`${styles.field} ${classes} ${error ? styles.error : ""}`}>
      <p className={styles.fieldLabel}>
        {label || null}
        {required && <span>*</span>}
      </p>
      <Field
        type={type}
        placeholder={placeholder}
        {...props}
        required={required}
        className={label === "Promo Code" ? "uppercase" : undefined}
      />
    </div>
  )
}

export const Dropdown = ({
  type,
  error,
  label,
  required,
  classes,
  defaultValue = "Select Category",
  options = ["Category 1", "Category 2", "Category 3"],
  changeSelected,
  noOutline = false,
  disabled,
  search,
}) => {
  const [dropdown, setDropdown] = useState(false)
  const toggle = () => setDropdown(prev => !prev)
  const [filter, setFilter] = useState(null)

  const filterOptions = value => {
    // const temp = options.filter(
    //   op => op.toLowerCase().indexOf(value.toLowerCase()) !== -1
    // )
    setFilter(value)
  }
  let op = []
  if (type === "cities") {
    op = filter
      ? options.filter(
          op => op.city.toLowerCase().indexOf(filter.toLowerCase()) !== -1
        )
      : options
  } else {
    op = filter
      ? options.filter(
          op => op.toLowerCase().indexOf(filter.toLowerCase()) !== -1
        )
      : options
  }
  return (
    <div>
      <div
        className={`${styles.dropdownWrapper} ${
          dropdown && !disabled ? styles.active : ""
        }`}
        onClick={() => toggle()}
      />
      <div
        className={`${styles.field} ${error ? styles.error : ""} ${classes}`}
      >
        {label && (
          <p
            className={`${styles.fieldLabel} ${
              disabled ? styles.disabled : ""
            }`}
          >
            {label}
            {required && <span>*</span>}
          </p>
        )}
        <div
          className={`${styles.dropdown} ${
            dropdown && !disabled ? styles.active : ""
          } ${noOutline ? styles.noOutline : ""} ${
            disabled ? styles.disabled : ""
          } ${error ? styles.error : ""}`}
          onClick={() => toggle()}
        >
          <div className="h-full flex justify-between items-center">
            <p
              className={`${
                error ? styles.errorText : ""
              } w-10/12 overflow-hidden`}
              dangerouslySetInnerHTML={{ __html: defaultValue }}
              style={{
                fontWeight: `${
                  defaultValue.indexOf("Select") >= 0 ? "500" : "600"
                }`,
              }}
            />
            <DropdownIcon className="w-8" />
          </div>
          <ul
            className={`${styles.options} ${
              dropdown && !disabled ? styles.active : ""
            }`}
          >
            {search && (
              <input
                className={styles.searchOptions}
                placeholder="Search City..."
                onClick={e => {
                  e.stopPropagation()
                }}
                onChange={e => {
                  filterOptions(e.target.value)
                }}
              />
            )}
            {op.map((option, index) => (
              <li
                key={index}
                className={styles.option}
                onClick={() =>
                  changeSelected(type === "cities" ? option.city : option)
                }
              >
                {type === "cities" ? option.city : option}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export const Textarea = ({
  label,
  required,
  placeholder,
  classes,
  error,
  description,
  setDescription,
}) => (
  <div className={`${styles.field} ${error ? styles.error : ""} ${classes}`}>
    <p className={styles.fieldLabel}>
      {label}
      {required && <span>*</span>}
    </p>
    <LoadableEditor
      tributeDescription={description}
      handleTributeDescription={setDescription}
    />
  </div>
)

export const CheckBox = ({ disabled, label, classes, ...props }) => (
  <label className={`${styles.checkboxContainer} ${classes}`}>
    <p className={styles.label} dangerouslySetInnerHTML={{ __html: label }} />
    <Field disabled={disabled} type="checkbox" {...props} name="featured" />
    <span className={styles.checkmark}></span>
  </label>
)

export const CustomDatePicker = ({
  error,
  label,
  value,
  placeholderText,
  onClick,
  classes,
  disabled,
}) => (
  <div
    className={`${styles.customDatePicker} ${error ? styles.error : ""} ${
      disabled ? styles.disabled : ""
    } ${classes}`}
  >
    <p className={styles.fieldLabel}>
      {label || null}
      <span>*</span>
    </p>
    <button
      type="button"
      className="flex justify-between items-center"
      onClick={onClick}
    >
      {value || (
        <span className={styles.placeholderText}>{placeholderText}</span>
      )}
      <CalenderIcon />
    </button>
  </div>
)

export const FilePreview = ({
  image,
  index,
  removeImage,
  videoUrl,
  disabled,
  assetId,
  assets,
  setAssets,
}) => {
  return (
    <React.Fragment>
      {!videoUrl ? (
        <div
          className={styles.filePreview}
          style={{
            backgroundImage: `url(${image})`,
          }}
        >
          <span
            className={styles.closeBtn}
            onClick={() =>
              !disabled && removeImage(index, assets, setAssets, assetId)
            }
          >
            <CloseBtn className={styles.normal} />
            <CloseBtn hover={true} className={styles.hovered} />
          </span>
        </div>
      ) : (
        <div className={styles.filePreview}>
          <video src={videoUrl} className="w-100 h-100"></video>
          <span
            className={styles.closeBtn}
            onClick={() =>
              !disabled && removeImage(index, assets, setAssets, assetId)
            }
          >
            <CloseBtn className={styles.normal} />
            <CloseBtn hover={true} className={styles.hovered} />
          </span>
        </div>
      )}
    </React.Fragment>
  )
}

export const FileUpload = ({
  type = "image",
  label,
  required,
  classes,
  upload,
  images,
  videoUrls,
  setVideoUrls,
  disabled,
}) => {
  const inputRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const uploadFile = e => {
    if (e.target.files[0]) {
      for (const file in e.target.files) {
        if (e.target.files[file].type) {
          const reader = new FileReader()
          const imageTitle = e.target.files[file].name
          const imageType = e.target.files[file].type

          reader.addEventListener("load", async () => {
            setLoading(true)
            await Axios.post(path.IMAGE_UPLOAD_TO_CLOUDINARY, {
              image: { url: reader.result },
            }).then(response => {
              upload(prev => [
                ...prev,
                {
                  url: response.data.uploadedImage,
                  image_id: response.data.image_id,
                  title: imageTitle,
                  type: imageType,
                },
              ])
              setLoading(false)
            })
          })
          reader.readAsDataURL(e.target.files[file])
        }
      }
    }
  }
  const handleVideo = async e => {
    // Set object URL as the video <source>
    const videoTitle = e.target.files[0].name
    const videoType = e.target.files[0].type
    const blob = document.querySelector("#file-input").files[0]
    const url = URL.createObjectURL(
      document.querySelector("#file-input").files[0]
    )
    try {
      setIsLoading(true)
      const CLOUDINARY_API = "https://api.cloudinary.com/v1_1/dqofhcymz/upload"
      const CLOUDINARY_UPLOAD_PRESENT = "nyhank2u"
      const formData = new FormData()
      formData.append("file", blob)
      formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESENT)
      const response = await axios({
        url: CLOUDINARY_API,
        method: "post",
        header: {
          Content_Type: "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Origin",
          "Access-Control-Allow-Credentials": true,
        },
        data: formData,
      })
      setIsLoading(false)
      setVideoUrls([
        ...videoUrls,
        {
          url,
          videoUrl: response.data.url,
          video_id: response.data.public_id,
          videoType,
          videoTitle,
        },
      ])
    } catch (error) {
      setIsLoading(false)
    }
    var reader = new FileReader()
    reader.onloadend = async () => {
      var base64data = reader.result
    }
    reader.readAsDataURL(blob)
  }

  const removeImageFunc = async (index, assets, setAssets, assetId) => {
    const updatedArray = assets

    let status
    updatedArray[index] = { ...updatedArray[index], isLoading: true }
    setAssets(() => [...updatedArray])
    await Axios.post(path.DELETE_IMAGE_FROM_CLOUDINARY, {
      id: assetId,
    }).then(response => {
      status = response.data.status
      if (response.data.status !== "not found") {
        updatedArray[index] = {
          ...updatedArray[index],
          isLoading: false,
        }
        updatedArray.splice(index, 1)
        setAssets(() => [...updatedArray])
      } else {
        updatedArray[index] = {
          ...updatedArray[index],
          isLoading: false,
        }
      }
    })
  }

  const ImageUpload = () => (
    <>
      <div className="flex flex-wrap justify-start items-start">
        {images &&
          images.map((image, index) =>
            !image.isLoading ? (
              <FilePreview
                loading={loading}
                removeImage={removeImageFunc}
                index={index}
                image={image.url}
                assetId={image.image_id}
                assets={images}
                setAssets={upload}
                key={index}
              />
            ) : (
              <div
                className={`flex justify-center items-center ${styles.loaderContainer}`}
              >
                <img src={loader} className={styles.loader} />
              </div>
            )
          )}
        {loading && (
          <div
            className={`flex justify-center items-center ${styles.loaderContainer}`}
          >
            <img src={loader} className={styles.loader} />
          </div>
        )}
        <div
          className={styles.uploadBtn}
          style={{ marginTop: images.length > 4 ? "20px" : "0" }}
          onClick={() => inputRef.current.click()}
        >
          <UploadPhoto />
        </div>
      </div>
      <input
        disabled={disabled}
        type="file"
        ref={inputRef}
        accept=".png, .jpg, jpeg"
        multiple="true"
        onChange={e => uploadFile(e)}
      />
    </>
  )
  const VideoUpload = () => {
    return (
      <>
        <div className="flex flex-wrap justify-start items-start">
          {videoUrls &&
            videoUrls.map(({ url, video_id, isLoading }, index) =>
              !isLoading ? (
                <FilePreview
                  index={index}
                  videoUrl={url}
                  assets={videoUrls}
                  setAssets={setVideoUrls}
                  removeImage={removeImageFunc}
                  assetId={video_id}
                />
              ) : (
                <div
                  className={`flex justify-center items-center ${styles.loaderContainer}`}
                >
                  <img src={loader} className={styles.loader} />
                </div>
              )
            )}
          {isLoading && (
            <div
              className={`flex justify-center items-center ${styles.loaderContainer}`}
            >
              <img src={loader} className={styles.loader} />
            </div>
          )}

          <div
            className={styles.uploadBtn}
            style={{ marginTop: videoUrls.length > 4 ? "20px" : "0" }}
            onClick={() => inputRef.current.click()}
          >
            <UploadVideo />
          </div>
        </div>
        <input
          id="file-input"
          disabled={disabled}
          type="file"
          ref={inputRef}
          accept=".mov, .mp4, .avi"
          onChange={handleVideo}
        />
      </>
    )
  }
  return (
    <div className={`${styles.field} ${styles.fileUpload} ${classes}`}>
      <p className={styles.fieldLabel}>
        {label || null}
        {required && <span>*</span>}
        {type === "image" && (
          <span className={styles.note}>
            <br />
            [The first picture you upload will be your main tribute photo]
          </span>
        )}
      </p>
      {type === "image" ? (
        <ImageUpload />
      ) : type === "video" ? (
        <VideoUpload />
      ) : null}{" "}
    </div>
  )
}
