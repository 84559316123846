import axios from "axios"
import path from "./path"

const Axios = axios.create({
  baseURL: path.BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
})

export default Axios
